<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="columns"
      :items="cancelOrderReason"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.reason`]="{ item }">
        <div class="truncate">{{ item.reason }}</div>
      </template>
      <template v-slot:[`item.driver_name`]="{ item }">
        <div v-if="item.driver_name[0] != null">
          {{ item.driver_name[0].first_name }}
          {{ item.driver_name[0].last_name }}
        </div>
      </template>
      <template v-slot:[`item.date_time`]="{ item }">
        {{ format_date(item.date_time) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Driver Cancel Order',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moduleName: "DriverCancelOrder",
      modulePermission: [],
      cancelOrderReason: [],
      search: "",
      loading: true,
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
    };
  },
  methods: {
    getDriverCancelOrder() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "driver/cancel/order/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.cancelOrderReason = response.data.driverCancelOrder.data;
            this.loading = false;
            this.totalPages = response.data.driverCancelOrder.last_page;
            this.startRecord = response.data.driverCancelOrder.from;
            this.endRecord = response.data.driverCancelOrder.to;
            this.totalRecord = response.data.driverCancelOrder.total;
            this.numbers = [];
            for (
              let num = response.data.driverCancelOrder.from;
              num <= response.data.driverCancelOrder.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getDriverCancelOrder();
    },
    format_date(value) {
      if (value) {
        var res = new Date(value * 1000);
        return moment(res).format("DD/MM/YYYY h:m:s");
      }
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getDriverCancelOrder();
    },
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px",
        },
        { sortable: false, text: "Id", value: "_id", sortable: false },
        { sortable: false, text: "Order Id", value: "order_id" },
        { sortable: false, text: "Driver Name", value: "driver_name" },
        { sortable: false, text: "Reason", value: "reason" },
        { sortable: false, text: "Date Time", value: "date_time" },
      ];
    },
  },
  mounted() {
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    this.getDriverCancelOrder();
  },
};
</script>
